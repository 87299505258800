import React, { useRef } from "react";
import Card from "../../components/FreatureCard";
import Slider from "react-slick";
import icon from "../../assets/image/svg/node.svg";
import icon2 from "../../assets/image/svg/node.svg";
import icon3 from "../../assets/image/svg/chat-45.svg";
import icon4 from "../../assets/image/svg/chat-45-2.svg";
import icon5 from "../../assets/image/svg/node.svg";

const Feature = ({ className, ...rest }) => {
  const elSlider = useRef();
  const sliderSettings = {
    autoplay: true,
    centerMode: true,
    centerPadding: "17%",
    slidesToShow: 2,
    arrows: false,
    touchMove: true,
    infinite: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerPadding: "80px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerPadding: "10%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  const featureCards = [
    {
      image: icon,
      title: "Flow Chart",
      text:
        "Visualize your Carbon impact as a Flow chart and analyze the roots of your footprint.",
    },
    {
      image: icon2,
      title: "Team Collaboration",
      text:
        "We can help your team collaborate and communicate to go carbon neutral.",
    },
    {
      image: icon3,
      title: "Fast Support",
      text:
        "Contact Support and we will Reach out to Help. Just go to the support tab.",
    },
    {
      image: icon4,
      title: "Frequeunt Updates",
      text:
        "We are constantly working to improve Carbonift, and your user experience.",
    },
    {
      image: icon5,
      title: "Cached Results",
      text:
        "Our platform gets faster after your first use, making the experience smoother",
    },
  ];

  return (
    <div className={className} {...rest}>
      {/* feature section */}
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-14"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 text-white mb-6 letter-spacing-n83">
                Your Footprint, Our Goal
              </h2>
              <p className="font-size-6 text-white mb-0">
                Our team is dedicated to building tools to improve your journey to a reduced carbon footprint.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Title */}
      </div>
      {/* feature slider */}
      <div className="row">
        <div className="col-12" data-aos="zoom-in" data-aos-delay={800}>
          {/* <div className="">
            </div> */}
          <Slider
            ref={elSlider}
            {...sliderSettings}
            className="l3-feature-slider d-flex"
          >
            {featureCards.map(({ image, title, text }, index) => {
              return (
                <Card image={image} title={title} text={text} key={index} />
              );
            })}
          </Slider>
        </div>
      </div>
      {/* End feature slider */}
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="mt-10 mt-lg-12 text-center"
            data-aos="zoom-in"
            data-aos-delay={1000}
          >
                  <a href="https://auth.monday.com/oauth2/authorize?client_id=b1800c5eb4bf5d826c027a646f9b6130&response_type=install">
                    <img
                      alt="Add to monday.com"
                      height="50"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
