import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home3/Hero";
import Company from "../sections/home3/Company";
import Feature from "../sections/home3/Feature";
import ContentOne from "../sections/home3/ContentOne";
import ContentTwo from "../sections/home3/ContentTwo";
import Review from "../sections/home3/Review";
import Pricing from "../sections/home3/Pricing";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
                  <a href="https://auth.monday.com/oauth2/authorize?client_id=b1800c5eb4bf5d826c027a646f9b6130&response_type=install">
                    <img
                      alt="Add to monday.com"
                      height="50"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
            </>
          ),
          footerStyle: "style4",
        }}
      >
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        {/* <Company className="pt-12 pb-9" /> */}
        <ContentOne className="pt-27 pt-lg-35 pb-lg-34 " />
        <Feature style={{backgroundColor :"#2A3758" }} className="pt-4 pt-lg-18 pb-15 pb-lg-25 border-bottom border-default-color-3" />
        {/* <Review className="bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" /> */}
        {/* <Pricing className="pt-13 pt-lg-25 pb-8 pb-lg-22 " /> */}
      </PageWrapper>
    </>
  );
};
export default IndexPage;
