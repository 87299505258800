import React from "react";

import DashBoardImage from "../../assets/image/home-3/png/CarboniftSC.png";
import YellowShape from "../../assets/image/home-3/png/yellow-shape.png";
import GreenShape from "../../assets/image/home-3/png/output-onlinepngtools.png";
import { Link } from "gatsby";

const Hero = ({className,...rest}) => {
    return (
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div
                className="text-center mb-11 mb-lg-24"
                data-aos="zoom-in"
                data-aos-delay={500}
              >
                <h1 className="font-size-11 mb-7">
                  Know Your Carbon Footprint
                </h1>
                <p className="font-size-7 px-lg-5 mb-0">
                  Get extensive carbon footprint report from simple spend data.
                </p>
                <div className="btn-group d-inline-block mt-10">
                  <a href="https://auth.monday.com/oauth2/authorize?client_id=b1800c5eb4bf5d826c027a646f9b6130&response_type=install">
                    <img
                      alt="Add to monday.com"
                      height="60"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                  <p className="d-block mt-6 font-size-3 mb-0 text-bali-gray letter-spacing-reset">
                    No credit card required
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Hero Image Group */}
          <div className="row justify-content-center">
            <div className="col-md-10 col-xs-11">
              <div className="l3-hero-image-group w-100">
                {/* Image One */}
                <div
                  className="img-1 shadow-13"
                  data-aos="fade-up"
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <img src={DashBoardImage} alt="" />
                  {/* Image Two */}
                  <div className="img-2">
                    <img
                      src={YellowShape}
                      alt=""
                      data-aos="fade-right"
                      data-aos-delay={700}
                      data-aos-once="true"
                    />
                  </div>
                  {/* Image Three */}
                  <div className="img-3">
                    <img
                      src={GreenShape}
                      alt=""
                      data-aos="fade-left"
                      data-aos-delay={900}
                      data-aos-once="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Hero Image Group */}
        </div>
      </div>
    );
}


export default Hero;